<template>
  <div>
    <loading-overlay v-if="updating" :text="''" />
    <v-container fluid class="px-6 py-6">
      <v-row class="m-0">
        <v-col cols="12" class="pt-0 mb-10 mt-5">
          <v-card class="mb-30 card card-shadow border-radius-xl py-5">
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <dropzone @newFile="setFile"></dropzone>
              </div>
              <!-- <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(newEvent.img) + ')',
                }"
              ></div> -->
            </div>
            <div class="card-header-padding">
              <v-row>
                <v-col>
                  <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                    Titelbild hochladen
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Event Informationen
              </h5>
              <v-form>
                <div class="ps-lg-5 mt-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Eventtitel</label
                      >
                      <v-text-field
                        :rules="rules"
                        required
                        outlined
                        v-model="newEvent.title"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Eventkürzel (für Codes)</label
                      >
                      <v-text-field
                        :rules="rules"
                        required
                        type="email"
                        outlined
                        v-model="newEvent.short"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Eventbeschreibung</label
                      >
                      <vue-editor v-model="newEvent.description"></vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Wichtige Hinweise (optional)</label
                      >
                      <vue-editor v-model="newEvent.hints"></vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >E-Mail-Vorlage</label
                      >
                      <vue-editor v-model="newEvent.html"></vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Anzahl Teilnehmer</label
                      >
                      <v-text-field
                        :rules="rules"
                        required
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        type="number"
                        v-model="newEvent.slots"
                      >
                      </v-text-field>

                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 mt-5 d-block"
                        >Veranstalter</label
                      >
                      <v-text-field
                        :rules="rules"
                        required
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        v-model="newEvent.organizer"
                      >
                      </v-text-field>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 mt-5 d-block"
                        >Website Veranstalter (optional)</label
                      >
                      <v-text-field
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        v-model="newEvent.website"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row justify="center" class="mt-1 mb-3">
                        <label for="datepicker">Startdatum und Zeit</label>
                      </v-row>
                      <v-row justify="center" class="mb-4">
                        <date-picker
                          id="datepicker"
                          v-model="newEvent.date"
                          mode="dateTime"
                          is24hr
                          :min-date="new Date()"
                        />
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Veranstaltungsort
              </h5>

              <div class="ps-lg-5 mt-5">
                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Straße</label
                    >
                    <v-text-field
                      :rules="rules"
                      required
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="newEvent.street"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" lg="4">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Postleitzahl</label
                    >
                    <v-text-field
                      :rules="rules"
                      required
                      type="number"
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="newEvent.zipcode"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" lg="4">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Ort</label
                    >
                    <v-text-field
                      :rules="rules"
                      required
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="newEvent.city"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" lg="4">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Land</label
                    >
                    <v-text-field
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      placeholder="Deutschland"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
          <!-- <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <div class="ps-lg-5">
                <v-row>
                  <v-col cols="12" md="6">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Anzahl Teilnehmer</label
                    >
                    <v-text-field  :rules="rules" required
                      
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      type="number"
                      v-model="newEvent.slots"
                    >
                    </v-text-field>

                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 mt-5 d-block"
                      >Veranstalter</label
                    >
                    <v-text-field  :rules="rules" required
                      
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="newEvent.organizer"
                    >
                    </v-text-field>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 mt-5 d-block"
                      >Website Veranstalter (optional)</label
                    >
                    <v-text-field  :rules="rules" required
                      
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="newEvent.website"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row justify="center" class="mt-1 mb-3">
                      <label for="datepicker">Startdatum und Zeit</label>
                    </v-row>
                    <v-row justify="center" class="mb-4">
                      <date-picker
                        id="datepicker"
                        v-model="newEvent.date"
                        mode="dateTime"
                        is24hr
                        :min-date="new Date()"
                      />
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card> -->
          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Rechnungsangaben
              </h5>

              <div class="ps-lg-5 mt-5">
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Preis Online in € (0.00€ = Gratis)</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                          v-model="newEvent.price"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Eintrittspreis Ermäßigt in € (0.00€ = Gratis)</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                          v-model="newEvent.price_lowered"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Preis Abendkasse in € (0.00€ = Gratis)</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                          v-model="newEvent.price_local"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Ermäßigungshinweise</label
                        >
                        <v-textarea
                          hide-details
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                          dense
                          flat
                          outlined
                          height="106"
                          name="input-7-4"
                          v-model="newEvent.price_lowered_info"
                          placeholder="Ermäßigungshinweise"
                        ></v-textarea>
                      </v-col>
                      <v-col>
                        <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Rechnungs-Prefix</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="newEvent.invoicePrefix"
                    >
                    </v-text-field>
                      </v-col>
                    </v-row>
                    
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <v-row class="">
                <v-col>
                  <v-row align="center" justify="space-around">
                    <v-btn
                      elevation="0"
                      height="43"
                      :disabled="checkForm"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-primary
                        py-2
                        px-6
                        me-2
                        mb-sm-0 mb-2
                      "
                      color="#5e72e4"
                      small
                      @click="postEvent"
                      >Event anlegen</v-btn
                    >
                    <v-btn
                      elevation="0"
                      height="43"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-secondary
                        py-2
                        px-6
                        me-2
                        mb-sm-0 mb-2
                      "
                      color="#5e72e4"
                      small
                      @click="$router.go(-1).catch(() => {})"
                      >Abbrechen</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12">
          <v-row class="mb-6 mt-10">
            <v-col
              v-for="(item, i) in events"
              :key="item.title + i"
              lg="4"
              class="pt-0 mb-10"
            >
              <v-card
                class="card card-shadow border-radius-xl py-5 text-center"
                @click="$router.push('/admin/events/details/' + item._id).catch(() => {})"
              >
                <div
                  class="mt-n11 mx-4 card-header position-relative z-index-2"
                >
                  <div class="d-block blur-shadow-image">
                    <img
                      :src="getImage(item.img)"
                      class="img-fluid shadow border-radius-lg"
                      :alt="item.image"
                    />
                  </div>
                  <div
                    class="colored-shadow"
                    v-bind:style="{
                      backgroundImage: 'url(' + getImage(item.img) + ')',
                    }"
                  ></div>
                </div>
                <h5 class="font-weight-normal text-typo text-h5 mt-7 mb-2 px-4">
                  <a
                    href="javascript:;"
                    class="text-decoration-none text-default"
                    >{{ item.title }}</a
                  >
                </h5>
                <p class="mb-0 text-body font-weight-light px-5">
                  {{ item.text }}
                </p>
                <hr class="horizontal dark my-6" />
                <div class="d-flex text-body mx-2 px-4">
                  <p class="mb-0 font-weight-normal text-body">Anmeldungen:</p>
                  <i
                    class="
                      material-icons-round
                      position-relative
                      ms-auto
                      text-lg
                      me-1
                      my-auto
                    "
                    >person</i
                  >
                  <p class="text-sm my-auto font-weight-light">
                    {{ item.entries.length }}
                  </p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
      <v-snackbar
        top
        v-model="snackbar.visible"
        :color="snackbar.color"
        class="snackbar-shadow"
      >
        <div class="d-flex align-start alert-notify">
          <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
            >notifications</v-icon
          >
          <p class="mb-0">
            <span class="font-size-root font-weight-600">{{
              snackbar.title
            }}</span>
            <br />
            {{ snackbar.message }}
          </p>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="snackbar.visible = false"
            v-bind="attrs"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
// import CalendarWidget from "./Widgets/CalendarWidget.vue";
import { VueEditor } from "vue2-editor";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Dropzone from "./Widgets/Dropzone.vue";
import coreData from "@/mixins/coreDataMixin";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "NewEvent",
  mixins: [coreData],
  components: {
    VueEditor,
    DatePicker,
    Dropzone,
  },
  data: function () {
    return {
      newEvent: {
        sponsors: [],
        hint: "",
        html: "",
      },
    };
  },

  mounted() {
    if (this.userInformations) {
      if (this.userInformations.companyName) {
        this.newEvent.organizer = this.userInformations.companyName;
      }
      if (this.userInformations.website) {
        this.newEvent.website = this.userInformations.website;
      }
    }
  },

  computed: {
    checkForm() {
      if (
        this.newEvent.title &&
        this.newEvent.description &&
        this.newEvent.date &&
        this.newEvent.file &&
        this.newEvent.organizer &&
        this.newEvent.price &&
        this.newEvent.street &&
        this.newEvent.city &&
        this.newEvent.zipcode &&
        this.newEvent.slots &&
        this.newEvent.invoicePrefix
      )
        return false;
      return true;
    },
  },

  methods: {
    setFile(file) {
      console.log("file emitted!");
      this.newEvent.file = file;
    },
    getImage(link) {
      if (!link) return null;
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },

    postEvent() {
      console.log("postevent");
      if (this.checkForm)
        return this.SnackbarShow(
          "Danger",
          "Fehler!",
          "Das Formular ist nicht vollständig!"
        );
      this.$store.commit("updating", true);
      return new Promise((resolve, reject) => {
        this.newEvent.sponsors
          .filter((sponsor) => sponsor.title)
          .forEach((sponsor) => {
            sponsor.codes = [];
            for (let index = 0; index < sponsor.slots; index++) {
              var rndCode = require("crypto")
                .randomBytes(64)
                .toString("hex")
                .substr(0, 4);
              var code =
                this.newEvent.short +
                "-" +
                sponsor.title.substr(0, 3) +
                "-" +
                rndCode;
              if (!sponsor.codes.find((code) => code.code == code)) {
                sponsor.codes.push({ code: code });
              } else {
                rndCode = require("crypto")
                  .randomBytes(64)
                  .toString("hex")
                  .substr(0, 4);
                code =
                  this.newEvent.short +
                  "-" +
                  sponsor.title.substr(0, 3) +
                  "-" +
                  rndCode;
                sponsor.codes.push({ code: code });
              }
            }
          });

        let formData = new FormData();
        formData.append("description", this.newEvent.description);
        formData.append("title", this.newEvent.title);
        formData.append("disclaimer", this.newEvent.disclaimer);
        formData.append("organizer", this.newEvent.organizer);
        formData.append("date", new Date(this.newEvent.date));
        formData.append("slots", this.newEvent.slots);
        formData.append("hints", this.newEvent.hints);
        formData.append("price", this.newEvent.price);
        formData.append("price_lowered", this.newEvent.price_lowered);
        formData.append("price_local", this.newEvent.price_local);
        formData.append("html", this.newEvent.html);
        formData.append(
          "sponsors",
          JSON.stringify(
            this.newEvent.sponsors.filter((sponsor) => sponsor.title)
          )
        );
        formData.append("short", this.newEvent.short);
        formData.append("manager", this.userInformations._id);
        formData.append("client_id", this.userInformations.client_id);
        formData.append("street", this.newEvent.street);
        formData.append("city", this.newEvent.city);
        formData.append("zipcode", this.newEvent.zipcode);
        formData.append("invoicePrefix", this.newEvent.invoicePrefix);
        formData.append("website", this.newEvent.website);

        if (this.newEvent.file) {
          formData.append("image", this.newEvent.file);
        }

        axiosAuth
          .post("events/", formData)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            this.$store.dispatch("getEventsAdmin");
            this.SnackbarShow(
              "Success",
              "Aktion erfolgreich!",
              "Event gespeichert!"
            );
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            this.$store.commit("authError", true);
            this.$store.commit("authMessage", error.response.data);
            this.SnackbarShow(
              "Danger",
              "Fehler!",
              "Etwas ist schief gelaufen..."
            );
            console.log(error.response);
            reject(error.response.data);
          });
      });
    },
  },
};
</script>
